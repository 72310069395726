import { useState, useCallback } from "react";
import { Storage } from "aws-amplify";
import { GalleryImage } from "../Types";
import { nanoid } from "nanoid";

interface ImageOptions {}

export const getImages = async (options: ImageOptions) => {
  //console.log("loading list of images from s3");
  const results = await Storage.list("originals/", { pageSize: "ALL" });

  //console.log({ results });

  const loadedImages: GalleryImage[] = [];
  if (results) {
    results.results.forEach(async (item) => {
      if (item.key && item.key != "") {
        const prefix =
          "https://genstream-storage-f590f0c3221919-main.s3.amazonaws.com/public/";
        const justKey = item.key.split("/")[1];
        //console.log("loaded", item, justKey);
        //@ts-ignore
        //const signedOriginalURL = await Storage.get(item.key);
        //const signedThumbnailURL = await Storage.get(`thumbs/${justKey}`);

        const imgId = item.eTag
          ? item.eTag.substring(2, item.eTag.length - 3)
          : nanoid();
        const imgToPush = {
          imgId,
          //original: signedOriginalURL,
          //thumbnail: signedThumbnailURL,

          original: prefix + "originals/" + justKey,
          thumbnail: prefix + "thumbs/" + justKey,
          ///@ts-ignore
          title: item.key.substring(5, item.key.length - 5),
          bookmarked: false,
          lastModified: item.lastModified,
          description: justKey.substring(4, justKey.length - 4),
        };
        //console.log({ imgToPush });
        loadedImages.push(imgToPush);
      }
    });
  }
  return loadedImages;
};

export const useGetImages = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState<GalleryImage[]>([]);

  const execute = async (options = {}) => {
    try {
      setIsLoading(true);
      const imageList = await getImages(options);
      setData(imageList);
      setIsLoading(false);
      return imageList;
    } catch (e: any) {
      setError(e);
      setIsLoading(false);
      throw e;
    }
  };

  return {
    isLoading,
    error,
    data,
    execute: useCallback(execute, []), // to avoid infinite calls when inside a `useEffect`
  };
};
