import "./App.css";
import { useEffect } from "react";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { CognitoUser } from "@aws-amplify/auth";

import S3Gallery from "./Components/S3Gallery/S3Gallery";

Amplify.configure(awsconfig);

function App({ signOut, user }) {
  useEffect(() => {
    //testAPI();

    return () => {
      console.log("cleanup");
    };
  }, []);

  return (
    <div>
      <S3Gallery user={user} />
    </div>
  );
}

export default withAuthenticator(App);
