import { useEffect, useState, useRef } from "react";
//@ts-ignore
import ImageGallery from "react-image-gallery";
import { useGetImages } from "../../Hooks/ImageList";
import { GalleryImage } from "../../Types";
import { CognitoUser } from "@aws-amplify/auth";
import GalleryOverlay from "./GalleryOverlay";
export interface S3GalleryProps {
  user: CognitoUser;
}

const S3Gallery = ({ user }: S3GalleryProps) => {
  const [loadInterval, setLoadInterval] = useState(1000);
  const [activeImages, setActiveImages] = useState<GalleryImage[]>([]);
  const [bookmarkedImages, setBookmarkedImages] = useState<number[]>([]);
  const [filterBookmarks, setFilterBookmarks] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageMap, setImageMap] = useState<Map<string, GalleryImage>>(
    new Map<string, GalleryImage>()
  );
  const [currentIdx, setCurrentIdx] = useState(0);
  const [bookmarked, setBookmarked] = useState(false);

  const { isLoading, data, execute } = useGetImages();
  const checkForNewImages = (imgsToCheck: GalleryImage[]) => {
    //console.log("Checking for new images");
    //console.log("Checking", imgsToCheck);

    const filteredImgs = imgsToCheck.filter((img: GalleryImage) => {
      return !imageMap.has(img.imgId);
    });

    //console.log(`${filteredImgs.length} new images found`);
    //console.log(imageMap);
    if (filteredImgs.length > 0) addImagesToMap(filteredImgs);
  };

  const filterImages = (bookmarked: boolean) => {
    //console.log("filter images");
    let imagesToUse = [];

    if (bookmarked) {
      //console.log("filtering to bookmarked images");
      imagesToUse = [...imageMap.values()].filter((img) => img.bookmarked);
    } else {
      //console.log("filtering no images");
      imagesToUse = [...imageMap.values()];
    }

    //console.log("sorting by last modified");
    imagesToUse = imagesToUse.sort((a, b) => {
      if (a.lastModified && b.lastModified) {
        return (
          a.lastModified.getMilliseconds() - b.lastModified.getMilliseconds()
        );
      } else {
        return 0;
      }
    });

    //console.log("updating active images");
    setActiveImages(imagesToUse);
  };

  useEffect(() => {
    //console.log("image map UE");
    filterImages(filterBookmarks);
  }, [imageMap]);

  useEffect(() => {
    //console.log("getImageList Hook UE");
    //console.log(`setting interval to ${loadInterval / 360000} minutes`);
    execute().then((imageList: GalleryImage[]) => {
      //console.log("first hook", imageList);
      checkForNewImages(imageList);
    });
    //setup a timer to check for new images
    const interval = setInterval(() => {
      try {
        //console.log("execute loadImages hook");
        execute().then((imageList: GalleryImage[]) => {
          checkForNewImages(imageList);
        });
      } catch (err) {
        //console.log("use of image loading hook failed ");
        //console.log(err);
      } finally {
        //console.log("executed hook");
      }
    }, loadInterval);

    return () => clearInterval(interval);
  }, [execute]);

  const addImagesToMap = (imgs: GalleryImage[]) => {
    //console.log("addImagesToMap");
    const newMap = new Map([...imageMap]);
    imgs.forEach((img) => {
      newMap.set(img.imgId, img);
    });
    setImageMap(newMap);
  };

  const toggleBookmark = (id: number) => {
    let key = activeImages[id].imgId;
    let newImage = imageMap.get(key);
    if (newImage) {
      newImage.bookmarked = !newImage.bookmarked;
      const newImages = new Map([...imageMap]).set(key, newImage);
      bookmarkImage(key, user);
      setBookmarked(newImage.bookmarked);
      setImageMap(newImages);
    }
  };

  const bookmarkImage = (key: string, user: CognitoUser) => {
    //TODO -> backend route to store the bookmarks
  };

  const galleryRef = useRef();

  //const bookmarkComponent = (props) => {
  //  //console.log(props);
  //  return <div>{/* {props.someProps.objectKey} */}</div>;
  //};

  const clickHandler = (event: any) => {
    if (galleryRef.current) {
      //@ts-ignore
      const currIdx = galleryRef.current.getCurrentIndex();
      const currentImage = activeImages[currIdx];
      toggleBookmark(currIdx);
      console.log("clicked on", currentIdx, currentImage);
    }
  };

  const slideHandler = (idx: number) => {
    setCurrentIdx(idx);
    setBookmarked(activeImages[idx].bookmarked);
  };

  useEffect(() => {
    //console.log("toggled bookmark UE");
  }, [bookmarked]);

  const customControl = () => {
    return (
      <div className="tags-overlay">
        {bookmarked ? (
          <img
            src="https://genstream-storage-f590f0c3221919-main.s3.amazonaws.com/public/noun-airplane-118919+1.png"
            width="200"
          />
        ) : (
          <br />
        )}
      </div>
    );
  };

  return (
    <>
      <GalleryOverlay />
      <ImageGallery
        ref={galleryRef}
        startIndex={currentIdx}
        autoplay="true"
        items={activeImages}
        onClick={clickHandler}
        onSlide={slideHandler}
        renderCustomControls={customControl}
      />
    </>
  );
};

export default S3Gallery;
