import { useEffect, useState, useRef } from "react";
import { GalleryImage } from "../../Types";
//@ts-ignore
export interface GalleryOverlayProps {}

const GalleryOverlay = ({}: GalleryOverlayProps) => {
  return (
    <div className="gallery-overlay">
      <div className="overlay-title">SMO Futures</div>
      <div className="overlay-logo">
        <img
          width="150"
          src="https://genstream-storage-f590f0c3221919-main.s3.amazonaws.com/public/dargroup.png"
        />
      </div>
    </div>
  );
};

export default GalleryOverlay;
